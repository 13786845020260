import React from "react";
import { useHistory } from "react-router-dom";
import { ExtractUserContext } from "../../../context/app_context";
import { useToasts } from "react-toast-notifications";
import { usePaystackPayment } from "react-paystack";
import { Modal, Overlay } from "../../../components/modal";

const ContinueModal = ({ data, dispatch }) => {
    const { addToast } = useToasts();
    const history = useHistory();
    const { reference, email, amount } = data;

    const successText = data.text
        ? `Your repayment of ₦${amount / 100} was successful`
        : "Your Card has been added successfully!";
    // const { appState } = ExtractUserContext();
    console.log({ data });
    const close = () => {
        dispatch({ type: "CHANGE", payload: { showContinueModal: false } });
    };
    console.log(data);
    const config = {
        reference,
        // reference: new Date().getTime(),
        email: email,
        amount: amount,
        publicKey: "pk_test_2a2a433e070e492f6d84c065f590a1a1314931c8",
    };

    // you can call this function anything
    const onSuccess = (reference) => {
        console.log({ reference });
        addToast(successText, {
            appearance: "success",
            autoDismiss: true,
        });
        // history.push("/customer/repayments");
        // console.log(reference);
    };

    // you can call this function anything
    // const onClose = () => {
    //   // implementation for  whatever you want to do when the Paystack dialog closed.
    //   console.log('closed')
    // }

    const initializePayment = usePaystackPayment(config);
    return (
        <div
            style={{ backgroundColor: "#000000", opacity: ".95" }}
            className="w-screen h-screen fixed top-0 right-0 bottom-0 left-0 z-50 flex justify-center items-center"
        >
            <button
                onClick={() => {
                    close();
                    initializePayment(onSuccess, () => {});
                }}
                className="whitespace-no-wrap  focus:outline-none rounded-sm px-5 py-2 bg-white text-gray-700 font-medium shadow"
            >
                {data.text ? data.text : "Process Your Card"}
            </button>
        </div>
    );
};

export default ContinueModal;
