import { lazy } from "react";

export default [
    {
        path: "/credit-amounts/all",
        Component: lazy(() =>
            import("./../../credit-amounts/view_all_credits")
        ),
        permission: [],
    },
    {
        path: "/credit-amounts/insight/:id",
        Component: lazy(() =>
            import("./../../credit-amounts/periculum-insight")
        ),
        permission: [],
    },
];
