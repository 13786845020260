import { permissionsAllowed } from "../utils/permissions";

export default {
    sidebar_data: [
        {
            // icon: require("./../assets/sidebar-icons/dashboard.svg"),
            icon: require("./../assets/sidebar-icons2/Home 2new loanx3.svg"),
            name: "Dashboard",
            route: "/dashboard",
            children: [],
        },
        {
            // icon: require("./../assets/sidebar-icons/users.svg"),
            icon: require("./../assets/sidebar-icons2/user (8)new loanx3.svg"),

            name: "Users",
            route: "/user",
            children: [
                {
                    name: "Add User",
                    route: "/user/add",
                    permission: [permissionsAllowed.add_customer],
                },
                {
                    name: "View Users",
                    route: "/user/view",
                    permission: [
                        permissionsAllowed.view_customer,
                        permissionsAllowed.add_customer,
                    ],
                },
            ],
        },
        {
            // icon: require("./../assets/sidebar-icons/customer.svg"),
            icon: require("./../assets/sidebar-icons2/Page-1new loanx3.svg"),
            name: "Borrowers",
            route: "/borrower",
            children: [
                {
                    name: "Create Borrower",
                    route: "/borrower/create",
                    permission: [permissionsAllowed.add_borrower],
                },
                {
                    name: "View Borrowers",
                    route: "/borrower/view",
                    permission: [
                        permissionsAllowed.view_borrower,
                        permissionsAllowed.add_borrower,
                        permissionsAllowed.delete_borrower,
                        permissionsAllowed.edit_borrower,
                    ],
                },
                {
                    name: "Create borrower group",
                    route: "/borrower/group/create",
                    permission: [permissionsAllowed.add_borrower_group],
                },
                {
                    name: "View borrower group",
                    route: "/borrower/group/view",
                    permission: [
                        permissionsAllowed.view_borrower_group,
                        permissionsAllowed.delete_borrower_group,
                        permissionsAllowed.edit_borrower_group,
                        permissionsAllowed.add_borrower_group,
                    ],
                },
                {
                    name: "Send sms",
                    route: "/borrower/send_sms",
                    permission: [],
                },
                {
                    name: "Send email",
                    route: "/borrower/send_email",
                    permission: [],
                },
                {
                    name: "Invite customer",
                    route: "/borrower/invite_customer",
                    permission: [],
                },
            ],
        },
        {
            // icon: require("./../assets/sidebar-icons/loan.svg"),
            icon: require("./../assets/sidebar-icons2/payment-cardnew loanx3.svg"),
            name: "Loans",
            route: "/loan",
            children: [
                { name: "Start a loan", route: "/loan/start", permission: [] },
                {
                    name: "View existing loans",
                    route: "/loan/all",
                    permission: [],
                },
                {
                    name: "View Pay in Fours",
                    route: "/loan/payin4",
                    permission: [],
                },
                { name: "Due loans", route: "/loan/due-loans", permission: [] },
                {
                    name: "Missed Repayments",
                    route: "/loan/missed-repayment",
                    permission: [],
                },
                {
                    name: "Loans in arrears",
                    route: "/loan/loans-in-arrears",
                    permission: [],
                },
                {
                    name: "No repayments",
                    route: "/loan/no-repayments",
                    permission: [],
                },
                {
                    name: "Late loans",
                    // route: "/loan/late-loans",
                    permission: [],
                },
                {
                    name: "Past Maturity",
                    route: "/loan/past-maturity",
                    permission: [],
                },
                {
                    name: "Principal outstanding",
                    route: "/loan/principal-outstanding",
                    permission: [],
                },
                {
                    name: "Loan Collateral",
                    route: "/loan/view-collateral",
                    permission: [],
                },
                {
                    name: "E-Signature",
                    route: "/loan/e-signature",
                    permission: [],
                },
                // {
                // 	name: "Loan Calculator",
                // 	route: "/loan/loan-calculator",
                // 	permission: [],
                // },
                // {
                // 	name: "Approved loans",
                // 	route: "/loan/approved-loans",
                // 	permission: [],
                // },
                // {
                // 	name: "Loan Summary",
                // 	route: "/loan/summary",
                // 	permission: [],
                // },
                {
                    name: "Guarantors",
                    route: "/loan/guarantor/all",
                    permission: [permissionsAllowed.add_guarantor],
                },
                {
                    name: "Loan comments",
                    route: "/loan/loan-comments",
                    permission: [],
                },
            ],
        },
        {
            // icon: require("./../assets/sidebar-icons/loan.svg"),
            icon: require("./../assets/sidebar-icons2/payment-cardnew loanx3.svg"),
            name: "Credit amounts",
            route: "/credit-amounts/all",
            children: [
                {
                    name: "View All Credts",
                    route: "/credit-amounts/all",
                    permission: [],
                },
            ],
        },
        {
            // icon: require("./../assets/sidebar-icons/money.svg"),
            icon: require("./../assets/sidebar-icons2/walletnew loanx3.svg"),

            name: "Disbursements",
            route: "/disbursement",
            children: [
                {
                    name: "Disburse a loan",
                    route: "/disbursement/loan",
                    permission: [],
                },

                {
                    name: "Disbursement History",
                    route: "/disbursement/history",
                    permission: [],
                },
            ],
        },

        {
            // icon: require("./../assets/sidebar-icons/repayments.svg"),
            icon: require("./../assets/sidebar-icons2/credit-card (8)new loanx3.svg"),
            name: "Repayments",
            route: "/repayment",
            children: [
                {
                    name: "View all repayments",
                    route: "/repayment/all",
                    permission: [],
                },
                {
                    name: "Make repayments",
                    route: "/repayment/make",
                    permission: [],
                },
                // { name: "Bulk repayments", permission: [] },
                // {
                // 	name: "Approve repayments",
                // 	route: "/repayment/approve-repayment",
                // 	permission: [],
                // },
            ],
        },
        {
            // icon: require("./../assets/sidebar-icons/repayments.svg"),
            icon: require("./../assets/sidebar-icons/api-users.svg"),
            name: "Api-Users",
            route: "/api-users",
            children: [
                {
                    name: "View all Api Users",
                    route: "/api-users/all",
                    permission: [],
                },
                {
                    name: "Add new Api User",
                    route: "/api-users/new",
                    permission: [],
                },
            ],
        },
        // {
        // 	// icon: require("./../assets/sidebar-icons/savings.svg"),
        // 	icon: require("./../assets/sidebar-icons2/savingsnew loanx3.svg"),
        // 	name: "Savings",
        // 	children: [
        // 		{ name: "View Savings Accounts", permission: [] },
        // 		{ name: "Add Savings Accounts", permission: [] },
        // 		{ name: "Savings Report", permission: [] },
        // 		{ name: "Savings Product Report", permission: [] },
        // 		{ name: "Cash Safe Management", permission: [] },
        // 	],
        // },
        // {
        // 	// icon: require("./../assets/sidebar-icons/transactions.svg"),
        // 	icon: require("./../assets/sidebar-icons2/XMLID_71_new loanx3.svg"),

        // 	name: "Transactions",
        // 	children: [
        // 		{ name: "View Savings Transactions", permission: [] },
        // 		{ name: "Approve Savings Transactions", permission: [] },
        // 	],
        // },
        {
            icon: require("./../assets/sidebar-icons/reports.svg"),
            icon: require("./../assets/sidebar-icons2/analysis-reportnew loanx3.svg"),
            name: "Reports",
            children: [
                {
                    name: "Borrower's Report",
                    route: "/report/borrower",
                    permission: [],
                },
                {
                    name: "Loan Report",
                    route: "/report/loan_report",
                    permission: [],
                },
                { name: "Loan Arrears Aging Report", permission: [] },
                {
                    name: "Collections Report",
                    route: "/report/collection_report",
                    permission: [],
                },
                {
                    name: "Collectors Report",
                    route: "/report/collections_by_staff_report",
                    permission: [],
                },
                { name: "Deferred Income", permission: [] },
                { name: "Deferred Income Monthly", permission: [] },
                {
                    name: "Disbursement Report",
                    route: "/report/disbursement_report",
                    permission: [],
                },
                {
                    name: "Fees Report",
                    permission: [],
                    route: "/report/fees_report",
                },
                {
                    name: "Loan Officer Report",
                    route: "/report/loan_officer_report",
                    permission: [],
                },
                {
                    name: "Loan Product Report",
                    route: "/report/loan_product_report",
                    permission: [],
                },
                {
                    name: "Monthly Report",
                    route: "/report/monthly_report",
                    permission: [],
                },
                {
                    name: "Outstanding Reports",
                    route: "/report/outstanding_report",
                    permission: [],
                },
                {
                    name: "Portfolio at Risk",
                    // route: "/report/portfolio_at_risk_report",
                    permission: [],
                },
                {
                    name: "At a Glance Report",
                    // route: "/report/at_a_glance_report",
                    permission: [],
                },
                {
                    name: "All Entries",
                    // route: "/report/all_entries_report",
                    permission: [],
                },
            ],
        },

        {
            // icon: require("./../assets/sidebar-icons/repayments.svg"),
            icon: require("./../assets/sidebar-icons2/payroll.svg"),
            name: "payroll",
            route: "/payroll-dashboard",
            children: [
                {
                    name: "Payroll Dashboard",
                    route: "/payroll-dashboard",
                    permission: [],
                },
                {
                    name: "View Payroll",
                    route: "/view-payroll",
                    permission: [],
                },
                {
                    name: "Add Payslip",
                    route: "/create-payslip",
                    permission: [],
                },
                // { name: "Bulk repayments", permission: [] },
                // {
                // 	name: "Approve repayments",
                // 	route: "/repayment/approve-repayment",
                // 	permission: [],
                // },
            ],
        },
        {
            // icon: require("./../assets/sidebar-icons/repayments.svg"),
            icon: require("./../assets/sidebar-icons2/expense.svg"),
            name: "Expense",
            route: "/view-expenses",
            children: [
                {
                    name: "View Expense",
                    route: "/view-expenses",
                    permission: [],
                },
                {
                    name: "Add Expense",
                    route: "/add-expense",
                    permission: [],
                },

                // { name: "Bulk repayments", permission: [] },
                // {
                // 	name: "Approve repayments",
                // 	route: "/repayment/approve-repayment",
                // 	permission: [],
                // },
            ],
        },

        {
            // icon: require("./../assets/sidebar-icons/money.svg"),
            icon: require("./../assets/sidebar-icons2/savings-icon.svg"),

            name: "Savings",
            route: "/savings",
            children: [
                {
                    name: "Savings Dashboard",
                    route: "/savings/dashboard",
                    permission: [],
                },
                {
                    name: "View Savings Account",
                    route: "/savings/view-savings-accounts",
                    permission: [],
                },
                // {
                // 	name: "Borrower's Saving",
                // 	route: "/savings/borrower-saving",
                // 	permission: [],
                // },
                {
                    name: "Savings Report",
                    route: "/savings/savings-report",
                    permission: [],
                },
                {
                    name: "Add Lien",
                    route: "/savings/add-lien",
                    permission: [],
                },
                {
                    name: "Savings Product Report",
                    route: "/savings/savings-product-report",
                    permission: [],
                },

                {
                    name: "Add Savings Account",
                    route: "/savings/add-savings-account",
                    permission: [],
                },
                {
                    name: "Savings Profile",
                    route: "/savings/savings-profile",
                    permission: [],
                },
                // {
                // 	name: "Savings Transaction",
                // 	route: "/savings/view-savings-transaction",
                // 	permission: [],
                // },
                // {
                // 	name: "Credit Savings Account",
                // 	route: "/savings/credit-savings-account",
                // 	permission: [],
                // },
                // {
                // 	name: "Debit Savings Account",
                // 	route: "/savings/debit-savings-account",
                // 	permission: [],
                // },
            ],
        },
        {
            // icon: require("./../assets/sidebar-icons/money.svg"),
            icon: require("./../assets/sidebar-icons2/savings-transaction.svg"),

            name: "Savings Transactions",
            route: "/savings-transactions",
            children: [
                {
                    name: "Deposit",
                    route: "/savings-transactions/deposit",
                    permission: [],
                },
                {
                    name: "Withdrawal",
                    route: "/savings-transactions/withdrawal",
                    permission: [],
                },
                {
                    name: "Transfer",
                    route: "/savings-transactions/transfer",
                    permission: [],
                },
                {
                    name: "View Savings Transaction",
                    route: "/savings-transactions/view",
                    permission: [],
                },
            ],
        },

        {
            // icon: require("./../assets/sidebar-icons/money.svg"),
            icon: require("./../assets/sidebar-icons2/investor.svg"),
            name: "Investor",
            route: "/investor",
            children: [
                {
                    name: "Investment Dashboard",
                    route: "/investor/dashboard",
                    permission: [],
                },
                {
                    name: "Add Investment Account",
                    route: "/investment/add",
                    permission: [],
                },
                {
                    name: "View Investment Accounts",
                    route: "/investment/view",
                    permission: [],
                },
                {
                    name: "Investor Profile",
                    route: "/investor/investor-profile",
                    permission: [],
                },
                {
                    name: "Loan Investment",
                    route: "/investment/loan-investment",
                    permission: [],
                },
                {
                    name: "Add Loan Investment",
                    route: "/investment/loan-investment/add",
                    permission: [],
                },
                // {
                // 	name: "View Investor",
                // 	route: "/investor/view",
                // 	permission: [],
                // },
                {
                    name: "Sms Investor",
                    route: "/investor/sms",
                    permission: [],
                },
                {
                    name: "Email Investors",
                    route: "/investor/email",
                    permission: [],
                },
                {
                    name: "Invite Investors",
                    route: "/investor/invite",
                    permission: [],
                },

                // {
                // 	name: "Add Investor Transaction Type ",
                // 	route: "/investor/investment-transaction-type",
                // 	permission: [],
                // },
                // {
                // 	name: "Add Investment Account",
                // 	route: "/investor/investment-account",
                // 	permission: [],
                // },
                // {
                // 	name: "Add Investment Account Fee ",
                // 	route: "/investor/investment-account-fee",
                // 	permission: [],
                // },
                // {
                // 	name: "View Investment Transaction Type ",
                // 	route: "/investor/investment-transaction-types",
                // 	permission: [],
                // },
                // {
                // 	name: "Withdrawal",
                // 	route: "/savings-transactions/withdrawal",
                // 	permission: [],
                // },
            ],
        },
        {
            // icon: require("./../assets/sidebar-icons/money.svg"),
            icon: require("./../assets/sidebar-icons2/investor-transaction.svg"),

            name: "Investment",
            route: "/investment",
            children: [
                {
                    name: "Deposit",
                    route: "/investment-account/deposit",
                    permission: [],
                },
                {
                    name: "Withdraw",
                    route: "/investment-account/withdrawal",
                    permission: [],
                },
                {
                    name: "Investment Transactions",
                    route: "/investment/investment-transactions",
                    permission: [],
                },
                // {
                // 	name: "View Savings Transaction",
                // 	route: "/savings-transactions/view",
                // 	permission: [],
                // },
            ],
        },
        {
            // icon: require("./../assets/sidebar-icons/money.svg"),
            icon: require("./../assets/sidebar-icons2/accounting.svg"),

            name: "Accounting",
            route: "/accounting",
            children: [
                {
                    name: "Cash Flow Accumulated",
                    route: "/accounting/cash-flow-accumulated",
                    permission: [],
                },
                {
                    name: "Monthly Cash flow",
                    route: "/accounting/cash-flow-monthly",
                    permission: [],
                },
                {
                    name: "Profit Loss",
                    route: "/accounting/profit-loss",
                    permission: [],
                },
                {
                    name: "Balance Sheet",
                    route: "/accounting/balance-sheet",
                    permission: [],
                },
                // {
                // 	name: "Withdrawal",
                // 	route: "/savings-transactions/withdrawal",
                // 	permission: [],
                // },
                // {
                // 	name: "View Savings Transaction",
                // 	route: "/savings-transactions/view",
                // 	permission: [],
                // },
            ],
        },
        {
            // icon: require("./../assets/sidebar-icons/money.svg"),
            icon: require("./../assets/sidebar-icons2/asset-management.svg"),

            name: "Asset Management",
            route: "/asset-management",
            children: [
                {
                    name: "View Asset Management",
                    route: "/asset-management/view",
                    permission: [],
                },
                {
                    name: "Add Asset Management",
                    route: "/asset-management/add",
                    permission: [],
                },
                {
                    name: "Asset Management Type",
                    route: "/asset-management/type",
                    permission: [],
                },
                {
                    name: "Add Asset Management Type",
                    route: "/asset-management/add-type",
                    permission: [],
                },
                // {
                // 	name: "Asset Management",
                // 	route: "/asset-management/asset-management",
                // 	permission: [],
                // },
                // {
                // 	name: "Monthly Cash flow",
                // 	route: "/accounting/cash-flow-monthly",
                // 	permission: [],
                // },
                // {
                // 	name: "Profit Loss",
                // 	route: "/accounting/profit-loss",
                // 	permission: [],
                // },
                // {
                // 	name: "Balance Sheet",
                // 	route: "/accounting/balance-sheet",
                // 	permission: [],
                // },
                // {
                // 	name: "Withdrawal",
                // 	route: "/savings-transactions/withdrawal",
                // 	permission: [],
                // },
                // {
                // 	name: "View Savings Transaction",
                // 	route: "/savings-transactions/view",
                // 	permission: [],
                // },
            ],
        },
        {
            // icon: require("./../assets/sidebar-icons/money.svg"),
            icon: require("./../assets/sidebar-icons2/other-income.svg"),

            name: "Other Incomes",
            route: "/other-incomes",
            children: [
                {
                    name: "View Other Incomes",
                    route: "/other-incomes/view",
                    permission: [],
                },
                {
                    name: "Add Other Incomes",
                    route: "/other-incomes/add-other-incomes",
                    permission: [],
                },
                {
                    name: "Other Incomes Types",
                    route: "/other-incomes/other-incomes-types",
                    permission: [],
                },
                {
                    name: "Add Other Incomes Types",
                    route: "/other-incomes/add-other-incomes-types",
                    permission: [],
                },
                // {
                // 	name: "Monthly Cash flow",
                // 	route: "/accounting/cash-flow-monthly",
                // 	permission: [],
                // },
                // {
                // 	name: "Profit Loss",
                // 	route: "/accounting/profit-loss",
                // 	permission: [],
                // },
                // {
                // 	name: "Balance Sheet",
                // 	route: "/accounting/balance-sheet",
                // 	permission: [],
                // },
                // {
                // 	name: "Withdrawal",
                // 	route: "/savings-transactions/withdrawal",
                // 	permission: [],
                // },
                // {
                // 	name: "View Savings Transaction",
                // 	route: "/savings-transactions/view",
                // 	permission: [],
                // },
            ],
        },
    ],

    bottom_sidebar_data: [
        // { name: "Investments" },
        // {
        // 	name: "Collateral Register",
        // 	// route: "/collateral/view"
        // },
        // { name: "Asset Management" },
        // { name: "Other Income" },
        // { name: "Staff" },
        // { name: "Expenses", route: "/view-expenses" },
        // { name: "Payroll", route: "/payroll-dashboard" },
        // { name: "Accounting" },
    ],
};
