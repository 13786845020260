import React, { Suspense, useContext } from "react";

import {
    Redirect,
    Route,
    Switch,
    useHistory,
    useLocation,
} from "react-router-dom";
import { CSSTransition, SwitchTransition } from "react-transition-group";

import { LoaderOverlay } from "../../components/loader";
import {
    AppProvider,
    AppContext,
    ExtractUserContext,
} from "../../context/app_context";
import { appConfig } from "../../utils/constants";
import { confirmIfStaffOrCustomer2 } from "../../utils/functions";
import Dashboard from "../dashboard/dashboard";
import { AppHeader } from "./header";
import borrower_routes from "./routes/borrower_routes";
import disbursement_routes from "./routes/disbursement_routes";
import api_user_routes from "./routes/api-user_routes";
import credit_amount_routes from "./routes/credit-amount_routes";
import report_routes from "./routes/report";
import other_routes from "./routes/bottom-sidebar-routes";
import loan_routes from "./routes/loan_routes";
import repayment_routes from "./routes/repayment_routes";
import settings_routes from "./routes/settings_routes";
import user_routes from "./routes/user_routes";
import { AppSidebar } from "./sidebar";
import PrivateRoute from "../../components/privateRoute";
import savings_routes from "./routes/savings_routes";
import investor_routes from "./routes/investor_routes";
import accounting_routes from "./routes/accounting_routes";
import assetManagementRoutes from "./routes/asset-management-routes";
import other_incomes_routes from "./routes/other_incomes_routes";

// import { ToastProvider } from "react-toast-notifications";

export const adminDashboardRoutes = [
    ...other_incomes_routes,
    ...assetManagementRoutes,
    ...accounting_routes,
    ...investor_routes,
    ...savings_routes,
    ...settings_routes,
    ...report_routes,
    ...user_routes,
    ...borrower_routes,
    ...loan_routes,
    ...disbursement_routes,
    ...api_user_routes,
    ...credit_amount_routes,
    ...repayment_routes,
    ...other_routes,
];
// console.log(adminDashboardRoutes);
function isLoggedIn() {
    return !!localStorage.getItem(appConfig.token_name2);
}

export function Main() {
    const history = useHistory();
    const location = useLocation();
    const { appState } = ExtractUserContext();

    if (!isLoggedIn()) {
        return <Redirect to={"/login"} />;
    }
    if (appState.user === null) {
        return <LoaderOverlay />;
    }
    if (
        isLoggedIn() &&
        confirmIfStaffOrCustomer2(appState.user) === "customer"
    ) {
        return <Redirect to={"/customer"} />;
    }

    const userPermission = appState.user.permissions.length
        ? appState.user?.permissions.map((each) => {
              return each.permit_name;
          })
        : [];
    console.log(userPermission);
    return (
        <div className="flex max-h-screen w-screen max-w-full overflow-hidden">
            <AppSidebar />
            <div
                style={{ background: "#F9F9FB" }}
                className="flex-1   h-screen overflow-x-hidden"
            >
                {/* flex flex-col */}
                {/* bg-gray-100 */}
                {/* <AppProvider> */}
                <AppHeader />
                <main className="w-full font-medium pb-16 px-8">
                    {/* flex-1 flex-shrink-0  overflow-y-auto */}
                    {!history.location.pathname.startsWith("/dashboard") && (
                        <button
                            onClick={() => history.goBack()}
                            className="flex items-center mb-5 mt-3"
                        >
                            <span className="text-xl font-black">&lsaquo;</span>
                            <span className="ml-1 text-xs font-semibold text-gray-500">
                                Back
                            </span>
                        </button>
                    )}
                    <Suspense fallback={<LoaderOverlay />}>
                        <SwitchTransition>
                            <CSSTransition
                                key={location.key}
                                addEndListener={(node, done) =>
                                    node.addEventListener(
                                        "transitionend",
                                        done,
                                        false
                                    )
                                }
                                classNames="fade"
                            >
                                <Switch location={location}>
                                    <Route exact path="/">
                                        <Redirect to="/dashboard" />
                                    </Route>
                                    <Route
                                        exact
                                        path="/dashboard"
                                        component={Dashboard}
                                    />

                                    {adminDashboardRoutes.map(
                                        (
                                            { path, Component, permission },
                                            index
                                        ) => {
                                            // console.log(permission);
                                            return (
                                                <Route
                                                    exact
                                                    key={`route${index}`}
                                                    path={path}
                                                    // component= {Component}
                                                    render={(routeProps) => (
                                                        <PrivateRoute
                                                            // userPermission={
                                                            // 	userPermission
                                                            // }
                                                            permissions={
                                                                permission
                                                            }
                                                        >
                                                            <Component
                                                                {...routeProps}
                                                            />
                                                        </PrivateRoute>
                                                    )}
                                                />
                                            );
                                        }
                                    )}
                                    <Route>
                                        <Redirect to="/dashboard" />
                                    </Route>
                                </Switch>
                            </CSSTransition>
                        </SwitchTransition>
                    </Suspense>
                </main>
                {/* </AppProvider> */}
            </div>
        </div>
    );
}
