const stagingURL = "https://loanx-backend-staging.onrender.com/api/v1";
const prodURL = "https://loanx-backend-live.onrender.com/api/v1";

//useless. dont consider this config.
export const config = {
    // base_url: "https://live.odiopay.com/api/v1",
    base_url: "https://loanx.odiopay.com/api/v1",
    token_name: "LOAN_TOKEN",
};

export const appConfig = {
    // base_url: "https://loanxx.herokuapp.com/api/v1",
    base_url: process.env.REACT_APP_ENV === "production" ? prodURL : stagingURL,
    // base_url: "http://localhost:8080/api/v1", //local
    // base_url: , //staging
    // base_url: "https://live.odiopay.com/api/v1",

    token_name: "LOAN_TOKEN",
    token_name2: "LOAN_CWS_TKN",
};
