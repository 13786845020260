import React, { createContext, useContext, useEffect, useReducer } from "react";

export const actions = {
    SET_PAGE_TITLE: "set-page-title",
    SET_USER_OBJECT: "set-user-object",
    SET_BORROWER_ID: "set-borrower-id",
    SET_CREATE_BORROWER: "set-create-borrower",
    SET_GUARANTOR_ADDED: "set-guarantor-added",
    SET_APPLY_FOR_LOAN: "set-apply-for-loan",
    SET_WORKING_STATUS_ID: "set-working-status-id",
    SET_WORKING_STATUS_DETERMINANT: "set-working-status-determinant",
    SET_BORROWER_ID_USER: "set-borrower-id-user",
    SET_CREDIT_REPORT: "set-credit-report",
    SET_LOAN_PRODUCT_DATA: "set-loan-product-data",
    SET_LOAN_DOCUMENT_ID: "set-loan-document-id",
};

const initialState = {
    page_title: "",
    user: null,
    borrower_id: null,
    working_status_determinant: null,
    credit_report: "null",
    loanProductData: null,
    loanDocumentId: null,
};

const appReducer = (state, action) => {
    switch (action.type) {
        case actions.SET_PAGE_TITLE: {
            return {
                ...state,
                page_title: action.value,
            };
        }
        case actions.SET_LOAN_DOCUMENT_ID: {
            return {
                ...state,
                loanDocumentId: action.value,
            };
        }
        case actions.SET_LOAN_PRODUCT_DATA: {
            return {
                ...state,
                loanProductData: action.value,
            };
        }
        case actions.SET_CREDIT_REPORT: {
            return {
                ...state,
                credit_report: action.value,
            };
        }
        case actions.SET_USER_OBJECT: {
            return {
                ...state,
                user: action.value,
            };
        }
        case actions.SET_WORKING_STATUS_DETERMINANT: {
            return {
                ...state,
                working_status_determinant: action.value,
            };
        }
        case actions.SET_BORROWER_ID: {
            return {
                ...state,
                borrower_id: action.value,
            };
        }
        case actions.SET_CREATE_BORROWER: {
            return {
                ...state,
                user: { ...state.user, create_borrower: action.value },
            };
        }
        case actions.SET_GUARANTOR_ADDED: {
            return {
                ...state,
                user: { ...state.user, guarantor_added: action.value },
            };
        }
        case actions.SET_APPLY_FOR_LOAN: {
            return {
                ...state,
                user: { ...state.user, apply_for_loan: action.value },
            };
        }
        case actions.SET_WORKING_STATUS_ID: {
            return {
                ...state,
                user: { ...state.user, working_status_id: action.value },
            };
        }
        case actions.SET_BORROWER_ID_USER: {
            return {
                ...state,
                user: { ...state.user, borrower_id: action.value },
            };
        }
        default: {
            return {
                ...state,
            };
        }
    }
};

export const AppContext = createContext(initialState);

export function AppProvider(props) {
    const [appState, appDispatch] = useReducer(appReducer, initialState);

    const setLoanDocumentId = (value) => {
        appDispatch({ type: actions.SET_LOAN_DOCUMENT_ID, value });
    };
    const setUserObject = (value) => {
        appDispatch({ type: actions.SET_USER_OBJECT, value });
    };
    const setLoanProductData = (value) => {
        appDispatch({ type: actions.SET_LOAN_PRODUCT_DATA, value });
    };

    const setWorkingStatusDeterminant = (value) => {
        appDispatch({ type: actions.SET_WORKING_STATUS_DETERMINANT, value });
    };

    const setCreateBorrower = (value) => {
        appDispatch({ type: actions.SET_CREATE_BORROWER, value });
    };

    const setBorrowerIdUser = (value) => {
        appDispatch({ type: actions.SET_BORROWER_ID_USER, value });
    };

    const setGuarantorAdded = (value) => {
        appDispatch({ type: actions.SET_GUARANTOR_ADDED, value });
    };

    const setWorkingStatusId = (value) => {
        appDispatch({ type: actions.SET_WORKING_STATUS_ID, value });
    };

    const setApplyForLoan = (value) => {
        appDispatch({ type: actions.SET_APPLY_FOR_LOAN, value });
    };

    const setCreditReport = (value) => {
        appDispatch({ type: actions.SET_CREDIT_REPORT, value });
    };

    const setPageTitle = (value) =>
        appDispatch({ type: actions.SET_PAGE_TITLE, value });

    const setBorrowerId = (value) =>
        appDispatch({ type: actions.SET_PAGE_TITLE, value });

    const fetchUserDetails = () => {};

    return (
        <AppContext.Provider
            value={{
                appState,
                appDispatch,
                setPageTitle,
                fetchUserDetails,
                setUserObject,
                setBorrowerId,
                setBorrowerIdUser,
                setCreateBorrower,
                setGuarantorAdded,
                setApplyForLoan,
                setWorkingStatusId,
                setWorkingStatusDeterminant,
                setCreditReport,
                setLoanProductData,
                setLoanDocumentId,
            }}
        >
            {props.children}
        </AppContext.Provider>
    );
}

export function usePageName(name) {
    const { setPageTitle } = useContext(AppContext);
    useEffect(() => {
        setPageTitle(name);
    }, [name]);
}

export const ExtractUserContext = () => {
    return useContext(AppContext);
};
