import { lazy } from "react";

export default [
    {
        path: "/api-users/all",
        Component: lazy(() => import("./../../api-users/api-users-table")),
        permission: [],
    },
    {
        path: "/api-users/new",
        Component: lazy(() => import("./../../api-users/new-api-user")),
        permission: [],
    },
];
