import { lazy } from "react";

export default [
    {
        path: "/loan/edit-collateral/:id",
        Component: lazy(() =>
            import(
                "./../../loan/loan_collateral/edilt_collateral/edit_collateral.js"
            )
        ),
        permission: [],
    },
    {
        path: "/loan/add-collateral",
        Component: lazy(() =>
            import(
                "./../../loan/loan_collateral/add_collateral/add_collateral.js"
            )
        ),
        permission: [],
    },
    {
        path: "/loan/add-esignature",
        Component: lazy(() =>
            import("./../../loan/e-signature/add_signature_template.js")
        ),
        permission: [],
    },
    {
        path: "/loan/edit-loan-template/:id",
        Component: lazy(() =>
            import("./../../loan/e-signature/edit_template.js")
        ),
        permission: [],
    },
    {
        path: "/loan/view-loan-template",
        Component: lazy(() =>
            import("./../../loan/e-signature/view_loan_template.js")
        ),
        permission: [],
    },

    {
        path: "/loan/e-signature",
        Component: lazy(() =>
            import("./../../loan/e-signature/view_esignature.js")
        ),
        permission: [],
    },
    {
        path: "/loan/view_loan_statement",
        Component: lazy(() =>
            import(
                "./../../loan/borrower_loan_single/loan_terms_components/view_loan_statement.js"
            )
        ),
        permission: [],
    },
    {
        path: "/loan/view-collateral",
        Component: lazy(() =>
            import(
                "./../../loan/loan_collateral/view_collateral/view_collateral.js"
            )
        ),
        permission: [],
    },
    {
        path: "/loan/document-upload/:id",
        Component: lazy(() =>
            import("./../../loan/document-upload-alternative.js")
        ),
        permission: [],
    },
    {
        path: "/loan/borrower_loan_single/:id",
        Component: lazy(() =>
            import("./../../loan/borrower_loan_single/borrower_loan_single.js")
        ),
        permission: [],
    },
    {
        path: "/loan/borrower_loan",
        Component: lazy(() =>
            import("./../../loan/borrower_loan/borrower_loan.js")
        ),
        permission: [],
    },
    {
        path: "/loan/view-remitta-report",
        Component: lazy(() => import("./../../loan/credit-report.js")),
        permission: [],
    },
    {
        path: "/loan/view-bank-statement",
        Component: lazy(() => import("./../../loan/mono_statement.js")),
        permission: [],
    },
    {
        path: "/loan/view-loan-schedule/:id",
        Component: lazy(() => import("./../../loan/viewLoanSchedule.js")),
        permission: [],
    },
    {
        path: "/loan/statement-insight/:id",
        Component: lazy(() => import("./../../loan/periculum-insight")),
        permission: [],
    },
    {
        path: "/loan/purchase-details/:id",
        Component: lazy(() => import("./../../loan/purchase-details")),
        permission: [],
    },
    {
        path: "/loan/start",
        Component: lazy(() => import("./../../loan/start_loan/start_loan.js")),
        permission: [],
    },
    {
        path: "/loan/late-loans",
        Component: lazy(() => import("./../../loan/late_loans.js")),
        permission: [],
    },
    {
        path: "/loan/all",
        Component: lazy(() => import("./../../loan/view_loans.js")),
        permission: [],
    },
    {
        path: "/loan/payin4",
        Component: lazy(() => import("./../../loan/view_payIn4s")),
        permission: [],
    },
    {
        path: "/loan/guarantor/add",
        Component: lazy(() =>
            import("./../../loan/guarantor/add-guarantor-components/index")
        ),
        permission: [],
    },
    {
        path: "/loan/guarantor/all",
        Component: lazy(() =>
            import("./../../loan/guarantor/view_guarantor.js")
        ),
        permission: [],
    },
    {
        path: "/loan/due-loans",
        Component: lazy(() => import("./../../loan/due_loan.js")),
        permission: [],
    },
    {
        path: "/loan/approved-loans",
        Component: lazy(() => import("./../../loan/approved_loans.js")),
        permission: [],
    },
    {
        path: "/loan/no-repayments",
        Component: lazy(() => import("./../../loan/no_repayments_loan.js")),
        permission: [],
    },
    {
        path: "/loan/past-maturity",
        Component: lazy(() => import("./../../loan/past-maturity.js")),
        permission: [],
    },
    {
        path: "/loan/summary",
        Component: lazy(() => import("./../../loan/loan_summary.js")),
        permission: [],
    },
    {
        path: "/loan/principal-outstanding",
        Component: lazy(() => import("./../../loan/principal_outstanding.js")),
        permission: [],
    },
    {
        path: "/loan/loan-calculator",
        Component: lazy(() => import("./../../loan/loan_calculator.js")),
        permission: [],
    },
    {
        path: "/loan/loans-in-arrears",
        Component: lazy(() => import("./../../loan/loans_in_arrears.js")),
        permission: [],
    },
    {
        path: "/loan/loan-comments",
        Component: lazy(() => import("../../loan/loan_comments/loan_comments")),
        permission: [],
    },
    {
        path: "/loan/loan-comments/add",
        Component: lazy(() =>
            import("../../loan/loan_comments/add_loan_comment.js")
        ),
        permission: [],
    },
    {
        path: "/loan/missed-repayment",
        Component: lazy(() => import("../../loan/missed_repayment")),
        permission: [],
    },
    {
        path: "/collateral/view",
        Component: lazy(() =>
            import("../../collateral_register/view_collateral.js")
        ),
        permission: [],
    },
    {
        path: "/collateral/add",
        Component: lazy(() =>
            import("../../collateral_register/add_collateral.js")
        ),
        permission: [],
    },
];
