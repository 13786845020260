import React, { useEffect, useRef } from "react";

import { Card } from "./cards";
import { LoaderOverlay } from "./loader";

export function Overlay(props) {
    return (
        <div className="fixed top-0 m-0 z-50 left-0 h-screen w-screen bg-blue-1 bg-opacity-25">
            {props.children}
        </div>
    );
}
export function Modal({ children, loading, close = () => {}, wider = false }) {
    const clickRef = useRef(null);
    useOutsideAlerter(clickRef, close);

    return (
        <Overlay>
            {loading && <LoaderOverlay />}
            <div
                ref={clickRef}
                style={{
                    maxHeight: "75%",
                    maxWidth: "85%",
                    width: wider ? "85%" : "",
                }}
                className="absolute-center inline-block overflow-y-auto"
            >
                {close && (
                    <button
                        onClick={close}
                        className="absolute top-0 right-0 font-bold m-2 rounded-full h-5 w-5 text-white bg-black text-base"
                    >
                        &times;
                    </button>
                )}
                {children}
            </div>
        </Overlay>
    );
}

export function ConfirmModal({ title, subtitle, close, confirm }) {
    return (
        <Modal close={close}>
            <Card>
                <button
                    onClick={close}
                    className="absolute top-0 right-0 font-bold m-2 rounded-full bg-black h-6 w-6 text-white text-base"
                >
                    &times;
                </button>
                <h5 className="whitespace-normal md:whitespace-no-wrap mt-6 mb-8 text-base font-semibold">
                    {title}
                </h5>
                <p className="mb-10 text-xs text-center">{subtitle}</p>

                <div className="flex justify-between">
                    <button
                        onClick={close}
                        className="border  px-6 py-1 rounded"
                    >
                        No
                    </button>
                    <button
                        onClick={confirm}
                        className=" border px-6 py-1 rounded"
                    >
                        Yes
                    </button>
                </div>
            </Card>
        </Modal>
    );
}

export function useOutsideAlerter(ref, handler) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                handler();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

export function RemittaDetailsModal({ title, close, data }) {
    const bank_name = data.bankName || data.salaryPaymentDetails[0].bank_name;
    const cname = data.customerName || "Still fixing";
    console.log(data);
    return (
        <Modal close={close}>
            <div
                style={{ width: "600px" }}
                className="px-5 py-2 bg-white shadow-2xl rounded"
            >
                <h5 className="whitespace-normal md:whitespace-no-wrap mt-6 mb-8 text-base font-semibold">
                    {`${title} ${cname}`}
                </h5>
                <div className="h-72 px-1 overflow-x-auto py-2">
                    {data.salaryPaymentDetails.map((each, i) => {
                        return (
                            <div>
                                <p className="mb-2">
                                    <span className="font-semibold">
                                        Payment Date
                                    </span>
                                    :&nbsp;&nbsp;&nbsp;{" "}
                                    {each.paymentDate
                                        ? each.paymentDate.split(" ")[0]
                                        : each.payment_date.split(" ")[0]}
                                </p>
                                <p className="mb-2">
                                    <span className="font-semibold">
                                        Salary Amount
                                    </span>
                                    :&nbsp;&nbsp;&nbsp;{" "}
                                    {each.amount || each.salary_amount}
                                </p>
                                <p className="mb-2">
                                    <span className="font-semibold">
                                        Account Number
                                    </span>
                                    :&nbsp;&nbsp;&nbsp;{" "}
                                    {each.accountNumber || each.account_number}
                                </p>
                                <p className="mb-2">
                                    <span className="font-semibold">
                                        Bank Code
                                    </span>
                                    :&nbsp;&nbsp;&nbsp;{" "}
                                    {each.bankCode || each.bank_code}
                                </p>
                                <p className="mb-3">
                                    <span className="font-semibold">
                                        Bank Name
                                    </span>
                                    :&nbsp;&nbsp;&nbsp; {bank_name}
                                </p>

                                {i < data.salaryPaymentDetails.length - 1 && (
                                    <>
                                        <hr />
                                        <br />
                                    </>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        </Modal>
    );
}
