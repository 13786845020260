import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/app_context";
import { getCustomerLoan } from "../../utils/api";
import { getAllLoansCustomer } from "../../utils/api-request";
import { useToasts } from "react-toast-notifications";

import TransactionRow from "../components/molecules/transactionRow";
import { LoaderOverlay, LoaderSpinner } from "../../components/loader";
import TransactionRowLoan from "../components/molecules/transaction-row-loan";
import useViewLoanCustomer from "./view-loan-components/useViewLoanCustomer";
import TableRowViewLoans from "./view-loan-components/table-row-view-loans";
import LastChildViewLoans from "./view-loan-components/last-child-view-loans";
import ContinueModal from "./view-loan-components/continue-modal";
import CurrentRepaymentModal from "./view-loan-components/current-repayment-modal";
import MakeRepaymentModal from "./view-loan-components/make-repayment-modal";

const CustomerLoan = () => {
    const {
        allState,
        prevPage,
        nextPage,
        refetchData,
        dispatch,
        createDebitCard,
        fetchCurrentRepayment,
    } = useViewLoanCustomer();
    // console.log(appState);

    const noLoan =
        allState.loading === "RESOLVED" && allState.data.length === 0;
    const showLoan =
        allState.loading === "RESOLVED" && allState.data.length > 0;
    // console.log(loanData);

    return (
        <div className="py-8 px-3">
            <div className="mb-10 flex px-10 justify-end">
                <Link
                    style={{ borderRadius: "20px" }}
                    className="px-6 py-2 cusBlue text-white"
                    to="/customer/loan-calculator"
                >
                    Apply
                </Link>
            </div>

            {allState.showContinueModal && (
                <ContinueModal data={allState.reference} dispatch={dispatch} />
            )}

            {allState.showRepaymentModal && (
                <MakeRepaymentModal
                    data={allState.repaymentData}
                    dispatch={dispatch}
                />
            )}

            {allState.currentRepaymentModal && (
                <CurrentRepaymentModal
                    dispatch={dispatch}
                    data={allState.currentRepaymentData}
                />
            )}

            {allState.showFullPageLoader && <LoaderOverlay />}

            {true && (
                <div className="flex justify-end mt-5 mb-5">
                    <button
                        onClick={createDebitCard}
                        className="whitespace-no-wrap ml-auto focus:outline-none rounded-sm px-5 py-2 bg-green-400 text-white font-medium shadow"
                    >
                        Add Debit Card
                    </button>
                </div>
            )}

            <div>
                <h6 className="mb-5 pl-3 text-gray-600">
                    Loans Application History
                </h6>

                {noLoan && (
                    <p className="text-center">
                        {" "}
                        You don't have any loans with us. Apply now
                    </p>
                )}

                {allState.loading === "FETCHING" && <LoaderSpinner />}

                {showLoan && (
                    <table
                        style={{ position: "relative", top: "-35px" }}
                        className={`cus-rep-table w-full`}
                    >
                        <thead>
                            <tr className="text-gray-500  text-sm">
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {allState.data.map((each) => {
                                return (
                                    <TableRowViewLoans data={each}>
                                        <LastChildViewLoans
                                            fetchCurrentRepayment={
                                                fetchCurrentRepayment
                                            }
                                            data={each}
                                            refetchData={refetchData}
                                            dispatch={dispatch}
                                        />
                                    </TableRowViewLoans>
                                );
                            })}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
};

export default CustomerLoan;
